import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import { Add } from 'react-ionicons';
import Button from 'react-bootstrap/Button';
import { Build } from 'react-ionicons';
import * as singleActions from '../../store/actions/single';
import Bound from './Bound';
import urls from '../../utils/urls';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

class EventTile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      preview: ''
    };
  }

  componentDidMount() {
    if (this.props.data) {
      if (this.props.data.image) {
        if (this.props.data.image.path) {
            urls.getS3Url(this.props.data.image.thumb || this.props.data.image.path, this.props.auth.token._id)
          .then((imageUrl) => {
            this.setState({
              preview: imageUrl
            });
          })
          .catch((error) => {
            console.error('Error loading image:', error);
          });
        }
      } else if (this.state.preview) {
        this.setState({ preview: '' });
      }
    }
  }

  getCanUpload = () => {
    if (this.props.data.event) {
      let videoEnd = dayjs.utc(this.props.data.event.videoEnd).add(31, 'hours');
      let now = dayjs();
      let diff = now.diff(videoEnd, 'minutes');
      return diff < 0;
    }
  };

  render() {
    return (
      <Col md={4} lg={6} xl={4}>
        <Bound>
          {this.props.more ? (
            <div
              className={'tile-container'}
              onClick={(e) => this.props.history.push(`/app/events`)}
              style={{ background: `rgba(0,0,0,.4)` }}>
              <div className={'tile-gradient'}></div>
              <div className={' text-center tile-symbol'}>
                <Add fontSize={'64px'} />
                <h3 className={'more'}>{this.props.more} More</h3>
              </div>
            </div>
          ) : (
            <div
              className={'tile-container'}
              onClick={(e) => {
                if (this.props.isDash) {
                  this.props.history.push(`/app/event-dashboard/${this.props.data._id}`);
                } else if (this.props.teams) {
                  this.props.history.push(`/app/teams/event/${this.props.data._id}`);
                } else {
                  this.props.history.push(`/app/events/view/${this.props.data._id}/details`);
                }
              }}
              style={{
                background: `url(${this.state.preview || this.props.placeholder}), rgba(0,0,0,.2)`
              }}>
              {!this.props.isDash && this.props.data ? (
                this.props.data.admins ? (
                  this.props.data.admins.indexOf(this.props.user.user._id) > -1 ? (
                    <Button
                      variant={'flat'}
                      className={'floating-edit-button'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.history.push(
                          `/app/event-dashboard/${this.props.data._id}/edit/1`
                        );
                      }}>
                      <Build />
                    </Button>
                  ) : null
                ) : null
              ) : null}
              <div className={'tile-gradient'}></div>

              <div className={'tile-content'}>
                <h4>{this.props.name}</h4>
                <h5>{this.props.date}</h5>
                {dayjs(this.props.data.registrationStart).format('MMM DD YYYY')} -{' '}
                {dayjs(this.props.data.judgingEnd).format('MMM DD YYYY')}
              </div>
            </div>
          )}
        </Bound>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  setSingle: (d) => {
    dispatch(singleActions.setSingleEntry(d));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EventTile));

class EventTileContainer extends React.Component {
  render() {
    return <Connected {...this.props} />;
  }
}

export default EventTileContainer;
