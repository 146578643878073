/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/30/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import { ArrowBack } from "react-ionicons";
import {PieChart} from "react-minimal-pie-chart";
import * as reportActions from '../../../store/actions/reports';
import Revenue from './Charts/Revenue';
import { Refresh } from 'react-ionicons'
import urls from "../../../utils/urls";

class Charts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.getKPI(this.props.match.params.id, this.props.auth.token._id);
        this.props.getRevenue(this.props.match.params.id, this.props.auth.token._id);
        this.props.getGeneratedData(this.props.match.params.id, this.props.auth.token._id);
    }

    componentDidUpdate(props, state) {

    }

    formatDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        })

    formatNumber = new Intl.NumberFormat('en-US', )

    exportTypes = () => [
        {
            name: 'Competition Entry Data',
            id: 'competitions'
        },
        {
            name: 'Competitor Data',
            id: 'competitors'
        },
        {
            name: 'Transaction Data',
            id: 'transactions'
        },
        {
            name: 'Awards Calculations',
            id: 'awards'
        },
        {
            name: 'Video Data',
            id: 'videos',
            type: 'online'
        }
    ]


    render() {
        return (
            <div className={'page-container'}>
                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>
                                {this.props.location.pathname.split("/").length > 3 ?
                                    <Button variant={"flat btn-round"} onClick={e => {

                                        this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}`);
                                    }}>
                                        <ArrowBack />
                                    </Button>
                                    : null}

                                Reports</h1>
                        </Col>
                    </Row>
                </div>
                <div>
                    <div className={"inline-chart-container"}>
                        <div className="inline-chart">
                            <div className={"pie-container report-page"} onClick={e => this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/transactions`)}>
                                <PieChart
                                    lineWidth={10}
                                    data={[
                                        {
                                            title: '',
                                            value: 100,
                                            color: '#19bcac'
                                        },
                                        {
                                            title: '',
                                            value: 0,
                                            color: '#dddddd'
                                        }
                                    ]}
                                    startAngle={-90}
                                />
                                <h3>{this.formatDollar.format(this.props.reports.income)}</h3>
                                <h5>Income</h5>
                            </div>
                        </div>

                        <div className="inline-chart">
                            <div className={"pie-container report-page"} onClick={e => this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/dancers`)}>
                                <PieChart
                                    lineWidth={10}
                                    data={[
                                        {
                                            title: 'Grades Dancers',
                                            value: this.props.reports.gradesDancers,
                                            color: '#19bcac'
                                        },
                                        {
                                            title: 'Champ Dancers',
                                            value: this.props.reports.champDancers,
                                            color: '#9754ef'
                                        }
                                    ]}
                                    startAngle={-90}
                                />
                                <h3>{this.formatNumber.format(this.props.reports.dancers)}</h3>
                                <h5>Dancers</h5>
                            </div>
                            <div className={"chart-explainer"}>
                                <div className={"explainer-row"}>
                                    <span className={"color"} style={{background: '#9754ef'}}></span> Championship: {this.props.reports.champDancers}
                                </div>
                                <div className={"explainer-row"}>
                                    <span className={"color"} style={{background: '#19bcac'}}></span> Grades: {this.props.reports.gradesDancers}
                                </div>
                            </div>
                        </div>

                        <div className="inline-chart">
                            <div className={"pie-container report-page"} onClick={e => this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/schools`)}>
                                <PieChart
                                    lineWidth={10}
                                    data={[
                                        {
                                            title: '',
                                            value: 100,
                                            color: '#19bcac'
                                        },
                                        {
                                            title: '',
                                            value: 0,
                                            color: '#dddddd'
                                        }
                                    ]}
                                    startAngle={-90}
                                />
                                <h3>{this.formatNumber.format(this.props.reports.schools)}</h3>
                                <h5>Schools</h5>
                            </div>
                        </div>

                        {!this.props.events.event.inPerson ?
                        <div className="inline-chart">
                            <div className={"pie-container report-page"} onClick={e => this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/entries`)}>
                                <PieChart
                                    lineWidth={10}
                                    data={[
                                        {
                                            title: '',
                                            value: Math.floor((this.props.reports.videos / this.props.reports.expectedVideos) * 100),
                                            color: '#19bcac'
                                        },
                                        {
                                            title: '',
                                            value: 100 - Math.floor((this.props.reports.videos / this.props.reports.expectedVideos) * 100),
                                            color: '#dddddd'
                                        }
                                    ]}
                                    startAngle={-90}
                                />
                                <h3>{this.formatNumber.format(this.props.reports.expectedVideos)}</h3>
                                <h5>Expected Videos</h5>
                            </div>
                        </div> : null}

                        <div className="inline-chart">
                            <div className={"pie-container report-page"} onClick={e => this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/schools`)}>
                                <PieChart
                                    lineWidth={10}
                                    data={[
                                        {
                                            title: '',
                                            value: 100,
                                            color: '#19bcac'
                                        },
                                        {
                                            title: '',
                                            value: 0,
                                            color: '#dddddd'
                                        }
                                    ]}
                                    startAngle={-90}
                                />
                                <h3>{this.formatNumber.format(this.props.reports.entryCompetitions)}</h3>
                                <h5>Entries</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"spacer"} />
                <div className={"spacer"} />
                <Revenue />
                <div className={"spacer"} />
                <div className={"text-center"}>
                    <Button variant={"link"} onClick={e => this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/transactions`)}>View Transaction Data</Button>
                </div>

                <div className={"spacer"} />
                <div className={"divider"} />
                <div className={"spacer"} />

                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>Export Data</h1>
                        </Col>
                    </Row>
                </div>

                <div className={"text-right hint-text"}>
                    <i>Depending on how much data is available, exports can take up to 5-10 minutes to prepare.<br />
                    We'll notify you when your report is ready.</i>
                </div>

                <div className={"spacer"} />
                <div className={"exports-section"}>


                    {this.exportTypes().map((item, i) => {
                        return (
                            <div className={"single-export"}>
                                <div className={"export-header"}>
                                    <div className={"single-export-title"}>
                                        <h4>{item.name}</h4>
                                    </div>

                                    <div className={"single-export-action"}>
                                        <Button variant={"primary"} disabled={this.props.events.event.generatingData[item.id]} onClick={e => {
                                            e.preventDefault();


                                            this.props.generateData(this.props.match.params.id, item.id, this.props.auth.token._id);
                                        }}><Refresh /> Generate</Button>
                                    </div>
                                </div>
                                <div className={"export-body"}>

                                    <ul className={"export-list"}>
                                        {this.props.reports.generatedReports ? this.props.reports.generatedReports[item.id] ? this.props.reports.generatedReports[item.id].length > 0 ? this.props.reports.generatedReports[item.id].map((rep, repIndex) => {
                                            return (
                                                <li><Button variant={"link"} onClick={e => {
                                                    e.preventDefault();
                                                    urls.getS3Url(rep.file.path)
                                                        .then((fileUrl) => {
                                                            var win = window.open(fileUrl, '_blank');
                                                            win.focus();
                                                        })
                                                        .catch((error) => {
                                                        console.error('Error loading image:', error);
                                                        });
                                                }}>{rep.file.name}</Button></li>
                                            )
                                        }) : <h4>No reports generated yet.</h4> : <h4>No reports generated yet.</h4> : <h4>No reports generated yet.</h4>}
                                    </ul>

                                </div>


                            </div>
                        )
                    })}
                </div>



            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events,
    reports: state.reports
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getKPI: (id, token) => {
        dispatch(reportActions.getKPI(id, token));
    },
    getRevenue: (id, token) => {
        dispatch(reportActions.getRevenue(id, token));
    },
    generateData: (id, type, token) => {
        dispatch(reportActions.generateData(id, type, token));
    },
    getGeneratedData: (id, token) => {
        dispatch(reportActions.getGeneratedData(id, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Charts));

class ChartsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default ChartsContainer;
