import AWS from 'aws-sdk';
import Api from '../utils/Api';

let config = {
  region: 'us-east-2',
  bucket: process.env.REACT_APP_MODE === 'production' ? 'onlinefeis' : 'feisfwddev',
};

AWS.config.update({
  region: config.region,
  signatureVersion: 'v3'
});


// MT 12/20/2024 Updating this to get signed URLs from the server. 
// TODO: This should really be in an actions file but there are many references to it and I need a quick fix
// TODO, also need to reject promise on error
function getS3Url(key, token, expiration) {
  return new Promise((resolve, reject) => {
    if (key) {
      const api = new Api(token);
      api.get('files/signedUrl', {key, expiration}, res => {
        resolve(res.data.data.url);
      })
    } else {
      resolve(null);
    }
  });
}

function getLongS3Url(key) {
  if (key) {
    const s3 = new AWS.S3({
      signatureVersion: 'v4'
    });
    const params = { Bucket: config.bucket, Key: key, Expires: 86400 };
    const url = s3.getSignedUrl('getObject', params);

    return url;
  } else {
    return null;
  }
}

let urls = {
  development: {
    api: '',
    s3: 'https://feisfwddev.s3.us-east-2.amazonaws.com/',
    host: 'http://localhost:9081/'
  }
};

switch (process.env.REACT_APP_MODE) {
  case 'beta':
    urls.development = {
      api: '',
      s3: 'https://feisfwddev.s3.us-east-2.amazonaws.com/',
      host: 'https://betaapi.feisfwd.com/'
    };
    break;
  case 'production':
    urls.development = {
      api: '',
      s3: 'https://onlinefeis.s3.us-east-2.amazonaws.com/',
      host: 'https://api.feisfwd.com/'
    };
    break;
  default:
    break;
}

export default {
  urls,
  getS3Url,
  getLongS3Url
};
