import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import * as singleActions from '../../store/actions/single';
import Col from 'react-bootstrap/Col';
import placeholder from '../../assets/images/placeholder1.png';
import urls from "../../utils/urls";
import dayjs from 'dayjs';

class EventModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: ''
        }
    }
    hideModal = () => {
        this.props.handleEventModal(false)

    }

    componentDidMount() {
        if (this.props.event && !this.state.preview) {
            if (this.props.event.image) {
                if (this.props.event.image._id) {
                    this.setState({preview: urls.getS3Url(this.props.event.image.thumb || this.props.event.image.path)});
                }
            }
        }



    }

    componentDidUpdate(props) {
        if ((this.props.event && !this.state.preview) || (this.props.event !== props.event)) {
            if (this.props.event.image) {
                if (this.props.event.image._id) {
                    this.setState({preview: urls.getS3Url(this.props.event.image.thumb || this.props.event.image.path)});
                }
            }
        }
    }
    formatPhoneNumber = (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };

        return null
    };

    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <img src={this.state.preview || placeholder} className={"img-responsive"} alt={this.props.event.name || 'Event Logo'} />
                            </Col>
                            <Col>
                                <h4>Event: {this.props.event.name}</h4>
                                <label><b>Contact email:</b> <a href={`mailto:${this.props.event.email}`}>{this.props.event.email}</a></label>
                                <label><b>Contact phone:</b> <a href={`tel:${this.props.event.phone}`}>{this.formatPhoneNumber(this.props.event.phone)}</a></label>
                                <label><b>Registration:</b> {dayjs(this.props.event.registrationStart).format("MMM DD YYYY")} - {dayjs(this.props.event.registrationEnd).format("MMM DD YYYY")}</label>
                                <label><b>Video Uploads:</b> {dayjs(this.props.event.videoStart).format("MMM DD YYYY")} - {dayjs(this.props.event.videoEnd).format("MMM DD YYYY")}</label>

                                <label><b>Judging:</b> {dayjs(this.props.event.judgingStart).format("MMM DD YYYY")} - {dayjs(this.props.event.judgingEnd).format("MMM DD YYYY")}</label>
                                <label><b>Syllabus:</b> {this.props.event.pdf ?
                                    <a href={"#syllabus"} onClick={e => {
                                        e.preventDefault();
                                        urls.getS3Url(this.props.event.pdf.path)
                                        .then((imageUrl) => {
                                            var win = window.open(imageUrl, '_blank');
                                            win.focus();
                                        })
                                        .catch((error) => {
                                        console.error('Error loading image:', error);
                                        });
                                    }}>Download PDF</a>
                                : 'No PDF yet'}</label>

                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EventModal));

class EventModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default EventModalContainer;
