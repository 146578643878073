import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { Add } from 'react-ionicons';
import urls from "../../utils/urls";
import Bound from './Bound';



class GeneralTile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            preview: ''
        }
    }

    componentDidMount(){
        this.init();
    }

    componentDidUpdate(props) {
        if(props.data !== this.props.data) {
            this.init();
        }
    }

    init = () => {
        //console.log("Mounting", this.props.data);
        if(this.props.data) {
            if(this.props.data.image) {
                if (this.props.data.image.path) {
                    this.setState({preview: urls.getS3Url(this.props.data.image.thumb || this.props.data.image.path)})
                } else {
                    this.setState({preview: ''})
                }
            } else {
                this.setState({preview: ''})
            }
        } else {
            this.setState({preview: ''})
        }
    }


    render() {
        return (
            <Bound>
                <div className={"square-tile animated bounceIn"} style={{
                    animationDelay: (this.props.index * 200).toString() + "ms"
                }} onClick={e => {
                    if (this.props.new) {
                        //console.log("Adding new");
                        this.props.onAddNew(e);
                    } if (this.props.isResults) {
                        this.props.history.push(`/app/results/dancers/${this.props.data._id}`);
                    } else {

                        if (this.props.data) {
                            this.props.history.push(`/app/${this.props.collection}/${this.props.data._id}`);
                        } else {
                            //this.props.history.push(`/app/${this.props.collection}/new`);
                        }

                    }
                }}>
                    <Bound>
                        {this.props.new ?
                            <div>
                                <div className={"tile-container avatar"} style={{background: `rgba(0,0,0,.4)`}}>
                                    <div className={" text-center tile-symbol"}>
                                        <Add fontSize={"64px"} />
                                    </div>



                                </div>
                                <div className={"tile-content"}>
                                    <h4>{this.props.label || "Add New Dancer"}</h4>
                                    <h5>{''}</h5>
                                </div>
                            </div>
                            :
                            <div className={"tile-fallback-background"}>
                                {this.state.preview ?
                                    <div className={"tile-background"} style={{background: `url(${this.state.preview ? this.state.preview : this.props.placeholder}), rgba(0,0,0,.2)`}} />
                                    : null}
                                <div className={"tile-container avatar"} style={{background: `url(${this.state.preview ? this.state.preview : this.props.placeholder}), rgba(0,0,0,.2)`}}>

                                </div>
                                <div className={"tile-content"}>
                                    <h4>{this.props.data.name || this.props.data.title}</h4>
                                    <h5>{this.props.data.school ? this.props.data.school.name : ''}</h5>
                                </div>
                            </div>
                        }
                    </Bound>

                </div>
            </Bound>
        )
/*
        return (
            <div className={"circle-tile"}>
                <Bound>
                    {this.props.new ?
                        <div>
                            <div className={"tile-container"} onClick={e => this.props.onAddNew(e)}style={{background: `rgba(0,0,0,.4)`}}>
                                <div className={" text-center tile-symbol"}>
                                    <Add fontSize={"64px"} />
                                </div>



                            </div>
                            <div className={"tile-content"}>
                                <h4>{this.props.label || "Add New Dancer"}</h4>
                                <h5>{''}</h5>
                            </div>
                        </div>
                        :
                        <div>
                            <div className={"tile-container"} onClick={e => {
                                if (this.props.isResults) {
                                    this.props.history.push(`/app/results/dancers/${this.props.data._id}`);
                                } else {

                                    this.props.history.push(`/app/${this.props.collection}/${this.props.data._id}`);
                                }
                            }} style={{background: `url(${this.state.preview ? this.state.preview : this.props.placeholder}), rgba(0,0,0,.2)`}}>

                            </div>
                            <div className={"tile-content"}>
                                <h4>{this.props.data.name || this.props.data.title}</h4>
                                <h5>{this.props.data.school ? this.props.data.school.name : ''}</h5>
                            </div>
                        </div>
                    }
                </Bound>

            </div>
        )

 */
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({

});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralTile));

class GeneralTileContainer extends React.Component {
    render() {
        return (

            <Connected {...this.props} />

        )
    }
}

export default GeneralTileContainer;
